<template>
  <data-table-wrapper :headers="headers" :items="value" :items-per-page="10" v-bind="$attrs" v-on="$listeners">
    <template v-slot:top>
      <slot name="top" />
      <user-prompt v-model="dialogDelete" @confirm="remove" />
    </template>
    <template v-for="(header) in headers" v-slot:[`item.${header.value}`]="{ item }">
      <v-edit-dialog v-if="header.value !== 'actions'" :key="header.value" :return-value.sync="item[header.value]" large
        @save="update(item)">
        {{ item[header.value] }}
        <template v-slot:input>
          <div class="mt-3">
            <v-text-field v-model="item.name" label="Name" hide-details
              v-if="header.value === 'name' && isBaseCompany" />
            <v-autocomplete v-model="item.name" label="Name" :items="configNames" hide-details
              v-else-if="header.value === 'name' && !isBaseCompany" />

            <v-select v-else-if="header.value === 'type'" v-model="item[header.value]" label="Type" :items="types"
              :disabled="!isBaseCompany" />

            <type-field v-else-if="header.value === 'value'" v-model="item[header.value]" label="Value"
              :type="item.type" />

            <v-text-field v-else v-model="item[header.value]" :label="header.text" />
          </div>
        </template>
      </v-edit-dialog>
    </template>

    <template v-slot:[`item.actions`]="{ item }">
      <v-icon medium color="error" @click="dialogDelete.show(item)">
        mdi-delete
      </v-icon>
    </template>
  </data-table-wrapper>
</template>

<script>
import TypeField from '../custom/TypeField.vue'
import { TYPES } from '../custom/types'
import { get } from 'vuex-pathify';

export default {
  components: { TypeField },
  props: {
    value: { type: Array, default: () => [] },
  },
  data() {
    return {
      dialogDelete: null,
      types: Object.values(TYPES),
      headers: [
        { text: 'Name', value: 'name' },
        { text: 'Type', value: 'type' },
        { text: 'Value', value: 'value' },
        { text: 'Description', value: 'description' },
        { text: 'Actions', value: 'actions' },
      ],
    }
  },
  computed: {
    ...get('company', ['selectedCompanyId']),
    configNames() {
      return this.$store.getters['configs/getConfigNames']
    },
    isBaseCompany() {
      return this.$store.getters['configs/isBaseCompany'](this.selectedCompanyId)
    }
  },
  methods: {
    update(item) {
      const previousType = item.type
      item.type = item.name
        ? this.$store.getters['configs/getConfig'](item.name)?.type
        : ''

      if (previousType !== item.type) {
        item.value = undefined
      }

      this.$emit('update', item)
    },
    remove(item) {
      this.$emit('remove', item)
    },
  },
}
</script>
